import React from "react";
import OLMap from "ol/Map";
import View from "ol/View";
import VectorTile from "ol/layer/VectorTile";
import Vector from "ol/layer/Vector";
import VectorTileSource from "ol/source/VectorTile";
import VectorSource from "ol/source/Vector";
import MVT from "ol/format/MVT";
import * as proj from "ol/proj";
import { defaults as defaultControls, ScaleLine } from "ol/control.js";
import { Feature } from "ol";
import { Polygon } from "ol/geom";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

import { connect } from "react-redux";
import { setMessage, setMap } from "../../actions";
import getStyle from "./styles/standard/getStyle";
import "./Map.css";
import settings from "../../config";

class MyMap extends React.Component {
  componentDidMount() {

    const extent = proj.transformExtent(
      [
        settings.EastBound,
        settings.SouthBound,
        settings.WestBound,
        settings.NorthBound,
      ],
      "EPSG:4326",
      "EPSG:3857"
    );

    var polyFeature = new Feature({
      geometry: new Polygon([
        [
          [settings.WestBound, settings.SouthBound],
          [settings.WestBound, settings.NorthBound],
          [settings.EastBound, settings.NorthBound],
          [settings.EastBound, settings.SouthBound],
          [settings.WestBound, settings.SouthBound],
        ],
      ]).transform("EPSG:4326", "EPSG:3857"),
    });

    this.layer = new VectorTile({
      source: new VectorTileSource({
        tileSize: 256,
        format: new MVT(),
        url:
          window.location.hostname === "localhost"
            ? "http://127.0.0.1:8000/maketiles/livetile?z={z}&x={x}&y={y}"
            : "https://tiles.outdoormaps.co.uk/{z}/{x}/{y}.pbf",
        minZoom: 7,
        maxZoom: 13,
      }),
      declutter: true,
      extent,
      style: (feature, res) => {
        return getStyle(
          res,
          feature.getProperties(),
          this.props.options,
          this.props.mode
        );
      },
    });

    this.scaleLineControl = new ScaleLine();

    this.view = new View({
      center: proj.fromLonLat([this.props.lng || -3, this.props.lat || 56]),
      zoom: this.props.zoom
        ? this.props.zoom < 13
          ? this.props.zoom
          : 13
        : 10,
      maxZoom: 17,
      minZoom: 7,
    });

    this.map = new OLMap({
      controls: this.props.options.showScale
        ? defaultControls().extend([this.scaleLineControl])
        : null,
      target: this.refs.mapContainer,
      layers: [
        // outline
        new Vector({
          source: new VectorSource({
            features: [polyFeature],
          }),
          minResolution: 400,
          style: [
            new Style({
              stroke: new Stroke({
                color: "#ddd",
              }),
            }),
          ],
        }),
        this.layer,
      ],
      view: this.view,
    });

    this.map.on("moveend", (e) => {
      const latlng = proj.transform(
        e.map.getView().getCenter(),
        "EPSG:3857",
        "EPSG:4326"
      );
      const zoom = e.map.getView().getZoom();
      this.props.setMap(latlng[1], latlng[0], zoom);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.layer.getSource().clear();
      this.view.setZoom(this.props.zoom + 0.0000000001); // super hacky way to force refesh
    }

    if (this.props.lat !== prevProps.lat || this.props.lng !== prevProps.lng) {
      this.view.setCenter(proj.fromLonLat([this.props.lng, this.props.lat]));
    }

    if (this.props.zoom !== prevProps.zoom) {
      this.view.setZoom(this.props.zoom);
    }

    // if (
    //   this.props.options.showContours !== prevProps.options.showContours ||
    //   this.props.options.showGrid !== prevProps.options.showGrid ||
    //   this.props.options.showHillNames !== prevProps.options.showHillNames ||
    //   this.props.options.showLakeNames !== prevProps.options.showLakeNames ||
    //   this.props.options.showPlaceNames !== prevProps.options.showPlaceNames
    // ) {
    //   this.layer.getSource().clear();
    //   this.view.setZoom(this.props.zoom + 0.0000000001); // super hacky way to force refesh
    // }

    if (this.props.options.showScale !== prevProps.options.showScale) {
      if (this.props.options.showScale) {
        this.map.addControl(this.scaleLineControl);
      } else {
        this.map.removeControl(this.scaleLineControl);
      }
    }
  }

  render() {
    return (
      <div className="mapContainer">
        <div ref="mapContainer" className="map" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    options: state.options,
    lat: state.map.lat,
    lng: state.map.lng,
    zoom: state.map.zoom,
    gps: state.gps,
    mode: state.map.mode,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessage: (message) => dispatch(setMessage(message)),
    setMap: (lat, lng, zoom) => dispatch(setMap(lat, lng, zoom)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMap);
