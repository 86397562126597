import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";

import zoom13lines from "./lines";
import zoom13areas from "./areas";

const getZIndex = (type) => {
  const indexes = [
    "cycleway",
    "grade1",
    "grade2",
    "grade3",
    "grade4",
    "grade5",
    "track",
    "bridleway",
    "path",
    "footway",
    "steps",
    "unclassified",
    "service",
    "residential",
    "motorway",
    "motorway_link",
    "trunk",
    "trunk_link",
    "primary",
    "primary_link",
    "secondary",
    "secondary_link",
    "tertiary",
    "railway",
    "line",
    "path",
    "track",

    "mud",
    "beach",
    "coast",

    "sea",
    "reservoir",
    "water",

    "canal",
    "river",
    "stream",

    "contour",

    "fell",
    "grassland",
    "wood",
    "forest",
    "wetland",
    "bare_rock",
    "elevpolygon2",
    "elevpolygon1",
    "coastline2",
  ];

  return indexes.reverse().indexOf(type) || 0;
};

export default (resolution, properties, options, mode) => {
  let linetype =
    properties.linetype ||
    properties.tracktype ||
    properties.highway ||
    properties.railway ||
    properties.power ||
    properties.waterway ||
    (properties.layer === "contours" ? "contour" : undefined) ||
    properties.natural ||
    properties.landuse ||
    (properties.layer === "grid" ? "grid" : undefined) ||
    (properties.layer === "coastline" ? "coastline" : undefined);

  if (properties.barrier) {
    linetype = "barrier";
  }

  let areatype =
    properties.natural ||
    properties.landuse ||
    // properties.building ||
    (properties.layer === "sea" ? "sea" : undefined) ||
    (properties.layer === "elevpolygon1" ? "elevpolygon1" : undefined) ||
    (properties.layer === "elevpolygon2" ? "elevpolygon2" : undefined) ||
    (properties.layer === "coastline2" ? "coastline2" : undefined);

  if (properties.building) {
    areatype = "building";
  }

  const pointtype = properties.natural || properties.place;

  const pointStyles = {
    city: options.showPlaceNames
      ? {
          text: properties.name,
          font: "bold 13pt Arial",
          fill: new Fill({
            color: "black",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 3,
          }),
        }
      : null,
    town: options.showPlaceNames
      ? {
          text: properties.name,
          font: "bold 11pt Arial",
          fill: new Fill({
            color: "black",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }
      : null,
    village: options.showPlaceNames
      ? {
          text: properties.name,
          font: "bold 10pt Arial",
          fill: new Fill({
            color: "black",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }
      : null,
    suburb: options.showPlaceNames
      ? {
          text: properties.name,
          font: "bold 9pt Arial",
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
          fill: new Fill({
            color: "black",
          }),
        }
      : null,
    peak: !["driving"].includes(mode)
      ? {
          text: `${properties.name ? properties.name : ""} ${
            properties.ele ? `${properties.ele}m` : ""
          }`,
          font: "italic 7pt Arial",
          fill: new Fill({
            color: "black",
          }),
        }
      : null,
  };

  if (
    [
      "track",
      "grade1",
      "grade2",
      "grade3",
      "grade4",
      "grade5",
      "path",
      "bridleway",
      "footway",
      "line", // powerline
      "rail",
      "cycleway",
    ].includes(linetype) &&
    ["driving"].includes(mode)
  ) {
    return;
  }

  if (
    [
      "track",
      "grade1",
      "grade2",
      "grade3",
      "grade4",
      "grade5",
      "path",
      "bridleway",
      "footway",
      "line", // powerline
      "rail",
    ].includes(linetype) &&
    ["roadCycling"].includes(mode)
  ) {
    return;
  }

  if (
    ["footway", "grade5", "line", "rail"].includes(linetype) &&
    ["gravelCycling"].includes(mode)
  ) {
    return;
  }

  const fillStyle = zoom13areas[areatype];
  let lineStyle = zoom13lines[linetype];

  // widen some routes
  if (
    (["gravelCycling"].includes(mode) &&
      [
        "grade1",
        "grade2",
        "cycleway",
        "bridleway",
        "unclassified",
        "track",
        "service",
        "tertiary",
      ].includes(linetype)) ||
    (["roadCycling"].includes(mode) &&
      ["tertiary", "unclassified"].includes(linetype))
  ) {
    // cheaky depp clone
    lineStyle = JSON.parse(JSON.stringify(lineStyle));
    lineStyle[0].style.width = lineStyle[0].style.width + 1.5;
  }

  if (
    ["roadCycling", "gravelCycling"].includes(mode) &&
    [
      "motorway",
      "motorway_link",
      "trunk",
      "trunk_link",
      "primary",
      "primary_link",
    ].includes(linetype)
  ) {
    lineStyle = lineStyle.map((line) => {
      return {
        ...line,
        style: {
          ...line.style,
          width: line.style.width - 1,
        },
      };
    });
  }

  if (
    ["roadCycling", "gravelCycling"].includes(mode) &&
    ["tertiary", "secondary", "unclassified"].includes(linetype)
  ) {
    lineStyle = lineStyle.map((line) => {
      return {
        ...line,
        style: {
          ...line.style,
          width: line.style.width + 1,
        },
      };
    });
  }

  const pointStyle = pointStyles[pointtype];
  let styles = [];

  let z = getZIndex(linetype || areatype);

  if (!(fillStyle || lineStyle || pointStyle)) {
    return;
  }

  if (pointStyle) {
    styles.push(
      new Style({
        text: new Text(pointStyle),
        zIndex: 2000,
      })
    );
  }

  if (
    lineStyle &&
    (linetype !== "contour" ||
      (linetype === "contour" &&
        options.showContours &&
        ["full", "roadCycling", "gravelCycling"].includes(mode)))
  ) {
    lineStyle.forEach((styleInfo) => {
      let style = styleInfo.style;
      let text;

      if (linetype === "contour") {
        style = {
          ...style,
          width:
            resolution < 50
              ? properties.rounded % 100 === 0
                ? 0.6
                : 0.2
              : 0.3,
        };
        if (resolution < 20 && properties.rounded % 50 === 0) {
          text = new Text({
            text: properties.rounded + "m",
            placement: "line",
            font: "7pt Arial",
            fill: new Fill({
              color: "#db8f60",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
          });
        }
        // }
      }

      if (
        (properties.highway === "motorway" ||
          properties.highway === "trunk" ||
          properties.highway === "primary") &&
        properties.ref
      ) {
        text = new Text({
          text: properties.ref,
          placement: "line",
          font: "bold 8pt Arial",
          stroke: new Stroke({
            color: "#fff",
            width: 4,
          }),
          fill: new Fill({
            color: "black",
          }),
        });
      } else if (properties.highway && resolution < 3 && properties.name) {
        text = new Text({
          text: properties.name,
          placement: "line",
          font: "8pt Arial",
          stroke: new Stroke({
            color: "#fff",
            width: 4,
          }),
          fill: new Fill({
            color: "black",
          }),
        });
      } else if (properties.waterway && properties.name) {
        text = new Text({
          text: properties.name,
          placement: "line",
          font: "italic 8pt Arial",
          stroke: new Stroke({
            color: "#fff",
            width: 4,
          }),
          fill: new Fill({
            color: "#1f9fd2",
          }),
        });
      }

      styles.push(
        new Style({
          stroke: new Stroke(style),
          zIndex: styleInfo.zIndex || properties.z_order + 101,
          text,
        })
      );
    });
  }

  if (fillStyle) {
    let text;
    if (areatype === "reservoir" || (areatype === "water" && properties.name)) {
      text = new Text({
        text: properties.name,
        font: "italic 8pt Arial",
        stroke: new Stroke({
          color: "#fff",
          width: 4,
        }),
        fill: new Fill({
          color: "#1f9fd2",
        }),
      });
    }

    styles.push(
      new Style({
        fill: new Fill(fillStyle),
        zIndex: z,
        text,
      })
    );
  }

  // base return for testing
  // return new Style(
  //   {
  //     stroke: new Stroke({
  //       color: '#fff',
  //       width: 4
  //     }),
  //     text: new ol.style.Text({
  //       text: properties.ref,
  //       scale: 1.2,
  //       // placement: 'line',
  //       fill: new Fill({
  //           color: 'rgba(0,0,0,.7)'
  //       }),
  //   })
  //   }
  // )

  return styles;
};
