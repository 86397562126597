import React, { Component } from 'react';

class Info extends Component {
  render() {
    return (
      <div>
        <p className="mui--text-headline">Info</p>
        <p>This map is created using data from various sources including www.openstreetmap.org.</p>
        <p>It is created to show information useful for outdoor activities, but always take a paper map and compass - and know how to use them.</p>
        <p>Please note, tracks shown on this map do not imply right of access. Please respect local rules.</p>
      </div>
    )
  }
}

export default Info
