import { combineReducers } from "redux";

import menuReducer from "./menuReducer";
import optionsReducer from "./optionsReducer";
import messageReducer from "./messageReducer";
import mapReducer from "./mapReducer";
import modalReducer from "./modalReducer";

const reducers = combineReducers({
  menu: menuReducer,
  map: mapReducer,
  message: messageReducer,
  options: optionsReducer,
  modal: modalReducer,
});

export default reducers;
