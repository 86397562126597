import "core-js/es6/set";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import { loadState, saveState } from "./localStorage";

import "muicss/dist/css/mui.css";

import App from "./App";

const store = configureStore(loadState());

store.subscribe(() => {
  saveState(store.getState());
});

const startApp = () => {
  render(
    <Provider store={store}>
      <App/>
    </Provider>,
    document.getElementById("root")
  );
};


startApp()
