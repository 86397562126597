import * as actionType from '../actions/ActionType';

const modalReducer = (state = { isOpen: false, content: null }, action) => {
  switch (action.type) {
    case actionType.OPEN_MODAL:
      return {
        isOpen: true,
        content: action.content
      };
    case actionType.CLOSE_MODAL:
      return {
        isOpen: false
      };
    default:
      return state
  }
}

export default modalReducer;
