import * as actionType from '../actions/ActionType';

const messageReducer = (state = {
  message: '',
  // center: null,
  // gps: null
}, action) => {
  switch (action.type) {
    case actionType.SET_STATUS_MESSAGE:
      return Object.assign(
        {},
        state,
        { message: action.message }
      )
  // case actionType.SET_STATUS_CENTER:
  //   return Object.assign(
  //     {},
  //     state,
  //     { center: action.coords }
  //   )
  // case actionType.SET_STATUS_GPS:
  //   return Object.assign(
  //     {},
  //     state,
  //     { gps: action.gps }
  //   )
    default:
      return state
  }
}

export default messageReducer;
