import React, { Component } from "react";
import Button from "muicss/lib/react/button";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import {
  toggleMenu,
  toggleContours,
  toggleScale,
  toggleTarget,
  togglePlaceNames,
  toggleHillNames,
  openModal,
  toggleLakeNames,
  setLocationUnit,
} from "../actions";
import Form from "muicss/lib/react/form";
import Checkbox from "muicss/lib/react/checkbox";
import Radio from "muicss/lib/react/radio";

import Info from "./Info";
import Feedback from "./Feedback";

import config from "./../config";

import "./Menu.css";

class Menu extends Component {
  onChangeOptionScale() {
    this.props.toggleScale();
  }

  onChangeOptionTarget() {
    this.props.toggleTarget();
  }
  onChangeOptionPlaceNames() {
    this.props.togglePlaceNames();
  }
  onChangeOptionHillNames() {
    this.props.toggleHillNames();
  }
  onChangeOptionLakeNames() {
    this.props.toggleLakeNames();
  }

  onChangeLocationUnit(x) {
    this.props.setLocationUnit(x);
  }

  render() {
    let mapName = config.name;
    let right;
    if (this.props.isOpen) {
      right = (
        <div
          className="menu_right"
          onClick={() => {
            this.props.toggleMenu();
          }}
        >
          <Button className="menu_open-button" variant="fab" color="primary">
            <FontAwesome name="times" />
          </Button>
        </div>
      );
    } else {
      right = (
        <div className="menu_right">
          <Button
            className="menu_open-button"
            onClick={() => {
              this.props.toggleMenu();
            }}
            variant="fab"
            color="primary"
          >
            <FontAwesome name="bars" />
          </Button>
        </div>
      );
    }
    return (
      <div className={`menu ${this.props.isOpen ? "open" : "closed"}  `}>
        <div className="menu_left mui--no-user-select">
          <div className="menu_left_inner">
            <div>
              <h2 className="mui--appbar-line-height">{mapName}</h2>
            </div>
            <div className="mui-divider" />
            <Form>
              <ul>
                <li>
                  <div className="mui--text-subhead">Display options:</div>
                </li>

                <li>
                  <Checkbox
                    label="Map center target"
                    checked={this.props.options.showTarget}
                    onChange={this.onChangeOptionTarget.bind(this)}
                  />
                </li>
                {/* <li>
                  <Checkbox
                    label="Contours"
                    checked={this.props.options.showContours}
                    onChange={() => this.props.toggleContours()}
                  />
                </li> */}
                <li>
                  <Checkbox
                    label="Scale bar"
                    checked={this.props.options.showScale}
                    onChange={this.onChangeOptionScale.bind(this)}
                  />
                </li>
                {/* <li>
                  <Checkbox
                    label="Place names"
                    checked={this.props.options.showPlaceNames}
                    onChange={this.onChangeOptionPlaceNames.bind(this)}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Hill names"
                    checked={this.props.options.showHillNames}
                    onChange={this.onChangeOptionHillNames.bind(this)}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Lake names"
                    checked={this.props.options.showLakeNames}
                    onChange={this.onChangeOptionLakeNames.bind(this)}
                  />
                </li> */}
                <li>
                  <Radio
                    name="locationUnit"
                    label="Use UK Grid Reference"
                    checked={this.props.options.locationUnits === "UKGridRef"}
                    onChange={() => this.onChangeLocationUnit("UKGridRef")}
                  />
                  <Radio
                    name="locationUnit"
                    label="Use Lat Lng"
                    checked={this.props.options.locationUnits === "LL"}
                    onChange={() => this.onChangeLocationUnit("LL")}
                  />
                </li>
              </ul>
              <div className="mui-divider" />
              <ul>
                <li
                  onClick={() => {
                    this.props.openModal(<Feedback />);
                  }}
                >
                  <div className="mui--text-subhead">
                    <FontAwesome name="commenting-o" /> Feedback
                  </div>
                </li>

                <li
                  onClick={() => {
                    this.props.openModal(<Info />);
                  }}
                >
                  <div className="mui--text-subhead">
                    <FontAwesome name="info" /> Info
                  </div>
                </li>
              </ul>
              <div className="mui-divider" />

              <ul>
                <li>
                  <a
                    className="donate"
                    href=" https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=65QU2XV7G5VGN&source=url"
                  >
                    Like this website? Consider making a donation
                  </a>
                </li>
              </ul>
            </Form>
          </div>
        </div>
        {right}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.menu.isOpen,
    options: state.options,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => dispatch(toggleMenu()),
    openModal: (content) => dispatch(openModal(content)),
    setLocationUnit: (option) => dispatch(setLocationUnit(option)),
    toggleScale: () => dispatch(toggleScale()),
    toggleTarget: () => dispatch(toggleTarget()),
    togglePlaceNames: () => dispatch(togglePlaceNames()),
    toggleHillNames: () => dispatch(toggleHillNames()),
    toggleLakeNames: () => dispatch(toggleLakeNames()),
    toggleContours: () => dispatch(toggleContours()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
