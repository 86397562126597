// import './css/fontawesome.css';
import "./App.css";
import "font-awesome/css/font-awesome.css";

import { connect } from "react-redux";
import React from "react";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
} from "react-router-dom";

import Menu from "./components/Menu";
import StatusBar from "./components/StatusBar";
import Modal from "./components/Modal";
import Map from "./views/Map";

import { setMode } from "./actions";

// import { Route, IndexRoute } from 'react-router';
// import { BrowserRouter, Route, Redirect } from 'react-router-dom'

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="main">
          <div className="main_content">
            <Menu />
            {/* <Map /> */}

            <Route path="/" component={Map} />

            <Modal />
            <StatusBar />
          </div>

          <div className="tabs">
            <NavLink
              activeClassName="selected"
              exact
              to=""
              className="tab"
              onClick={() => {
                this.props.setMode("full");
              }}
            >
              <h2>Topo Map / MTB</h2>
              <p>Full detail</p>
            </NavLink>
            <NavLink
              activeClassName="selected"
              to="driving"
              className="tab"
              onClick={() => {
                this.props.setMode("driving");
              }}
            >
              <h2>Driving</h2>
              <p>No paths or contours</p>
            </NavLink>
            <NavLink
              activeClassName="selected"
              to="road-cycling"
              className="tab"
              onClick={() => {
                this.props.setMode("roadCycling");
              }}
            >
              <h2>Road cycling</h2>
              <p>No paths, smaller roads</p>
            </NavLink>
            <NavLink
              activeClassName="selected"
              to="gravel-cycling"
              className="tab"
              onClick={() => {
                this.props.setMode("gravelCycling");
              }}
            >
              <h2>Gravel cycling</h2>
              <p>Highlight small roads and tracks</p>
            </NavLink>
          </div>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMode: (mode) => dispatch(setMode(mode)),
  };
};

function mapStateToProps(state) {
  return {
    mode: state.map.mode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
