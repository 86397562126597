import * as actionType from "./ActionType";

// menu
export const openMenu = () => ({
  type: actionType.OPEN_MENU,
});

export const toggleMenu = () => ({
  type: actionType.TOGGLE_MENU,
});

export const closeMenu = () => ({
  type: actionType.CLOSE_MENU,
});

// modal
export const openModal = (content) => ({
  type: actionType.OPEN_MODAL,
  content,
});

export const closeModal = () => ({
  type: actionType.CLOSE_MODAL,
});

// options
export const toggleGrid = () => ({
  type: actionType.TOGGLE_GRID,
});
export const toggleTarget = () => ({
  type: actionType.TOGGLE_TARGET,
});
export const toggleScale = () => ({
  type: actionType.TOGGLE_SCALE,
});
export const togglePlaceNames = () => ({
  type: actionType.TOGGLE_PLACENAMES,
});
export const toggleHillNames = () => ({
  type: actionType.TOGGLE_HILLNAMES,
});
export const toggleLakeNames = () => ({
  type: actionType.TOGGLE_LAKENAMES,
});
export const toggleContours = () => ({
  type: actionType.TOGGLE_CONTOURS,
});
export const setDistanceUnit = () => ({
  type: actionType.SET_DISTANCE_UNIT,
});
export const setLocationUnit = (unit) => ({
  type: actionType.SET_LOCATION_UNIT,
  unit,
});

// status bar
export const setMessage = (message) => ({
  type: actionType.SET_STATUS_MESSAGE,
  message,
});

// map
export const setMap = (lat, lng, zoom) => {
  return {
    type: actionType.SET_MAP,
    lat,
    lng,
    zoom,
  };
};
export const setZoom = (zoom) => {
  return {
    type: actionType.SET_ZOOM,
    zoom,
  };
};

export const setMode = (mode) => {
  return {
    type: actionType.SET_MODE,
    mode,
  };
};
