import * as actionType from '../actions/ActionType';

const moenuReducer = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case actionType.OPEN_MENU:
      return {
        isOpen: true
      };
    case actionType.CLOSE_MENU:
      return {
        isOpen: false
      };
    case actionType.TOGGLE_MENU:
      return {
        isOpen: !state.isOpen
      };
    default:
      return state
  }
}

export default moenuReducer;
