import React from "react";
import { connect } from "react-redux";
import "./Map.css";
import { setMap } from "../actions";
// import { latLngToGrid } from '../utils';
import TargetLayer from "../components/TargetLayer";

import MyMap from "../components/map/Map";

class Map extends React.Component {

  setMapLocation = (lat, lng, zoom) => {
    this.props.setMap(lat, lng, zoom);
  };

  componentDidMount() {}

  render() {
    let target;

    if (this.props.options.showTarget) {
      target = <TargetLayer />;
    } else {
      target = null;
    }

    return (
      <div className="mapPage">
        <MyMap />
        {target}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    options: state.options,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMap: (lat, lng, zoom) => dispatch(setMap(lat, lng, zoom)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
