import * as actionType from '../actions/ActionType';

const optionsReducer = (state = {
    showGrid: true,
    showTarget: true,
    showScale: true,
    showPlaceNames: true,
    showHillNames: true,
    showContours: true,
    locationUnits: 'UKGridRef'
  }, action) => {
  switch (action.type) {
    case actionType.TOGGLE_GRID:
      return Object.assign(
        {},
        state,
        { showGrid: !state.showGrid }
      )
    case actionType.SET_LOCATION_UNIT:
      return Object.assign(
        {},
        state,
        { locationUnits: action.unit}
      )
    case actionType.TOGGLE_CONTOURS:
      return Object.assign(
        {},
        state,
        { showContours: !state.showContours }
      )
    case actionType.TOGGLE_TARGET:
      return Object.assign(
        {},
        state,
        { showTarget: !state.showTarget }
      )
    case actionType.TOGGLE_SCALE:
      return Object.assign(
        {},
        state,
        { showScale: !state.showScale }
      )
    case actionType.TOGGLE_PLACENAMES:
      return Object.assign(
        {},
        state,
        { showPlaceNames: !state.showPlaceNames }
      )
    case actionType.TOGGLE_HILLNAMES:
      return Object.assign(
        {},
        state,
        { showHillNames: !state.showHillNames }
      )
    case actionType.TOGGLE_LAKENAMES:
      return Object.assign(
        {},
        state,
        { showLakeNames: !state.showLakeNames }
      )
    default:
      return state
  }
}

export default optionsReducer;
