import React, { Component } from "react";

class Feedback extends Component {
  render() {
    return (
      <div>
        <p className="mui--text-headline">Feedback</p>
        <p>
          Most of the data used to create this map is from
          www.openstreetmap.org, and I have tried to include features that help
          outdoor activities.
        </p>
        <p>
          If you have any comments about the map content/styles, or other
          features in the app, please email me on the following
        </p>
        <p>jonathan.a.shutt@gmail.com</p>
      </div>
    );
  }
}

export default Feedback;
