import React from 'react'

import './TargetLayer.css';
class Target extends React.Component {



  render(){
    return <div className="target"></div>
  }
};


export default Target
