import config from "./config";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    let json = JSON.parse(serializedState);
    if (json.map.zoom > config.maxPreInstalledZoom) {
      json.map.zoom = config.maxPreInstalledZoom;
    }
    return json;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
    // console.log(localStorage);
    // localStorage.setItem('bgcolor', 'red');
  } catch (err) {
    console.log(err);
  }
};
