import React, { Component } from 'react';
import Button from 'muicss/lib/react/button';
import { connect } from 'react-redux';
import { closeModal } from '../actions';
import './Modal.css';

class Modal extends Component {

  onCloseModal() {
    this.props.closeModal();
  }

  render() {
      return (
        <div className={`modal ${this.props.isOpen ? 'open' : 'closed' }  `} >
          <div className='modal__content'>
            {this.props.content}
            <p>
              <Button color="primary" onClick={() => { this.props.closeModal() }}>Close</Button>
            </p>
          </div>
          <div className='modal__bg' onClick={() => { this.props.closeModal() }}>
          </div>
        </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    isOpen: state.modal.isOpen,
    content: state.modal.content,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
