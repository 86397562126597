export function latLngToGrid(lat, lng) {

  var φ = toRadians(lat);
  var λ = toRadians(lng);

  var a = 6377563.396, b = 6356256.909;              // Airy 1830 major & minor semi-axes
  var F0 = 0.9996012717;                             // NatGrid scale factor on central meridian
  var φ0 = toRadians(49), λ0 = toRadians(-2);        // NatGrid true origin is 49°N,2°W
  var N0 = -100000, E0 = 400000;                     // northing & easting of true origin, metres
  var e2 = 1 - (b*b)/(a*a);                          // eccentricity squared
  var n = (a-b)/(a+b), n2 = n*n, n3 = n*n*n;         // n, n², n³

  var cosφ = Math.cos(φ), sinφ = Math.sin(φ);
  var ν = a*F0/Math.sqrt(1-e2*sinφ*sinφ);            // nu = transverse radius of curvature
  var ρ = a*F0*(1-e2)/Math.pow(1-e2*sinφ*sinφ, 1.5); // rho = meridional radius of curvature
  var η2 = ν/ρ-1;                                    // eta = ?

  var Ma = (1 + n + (5/4)*n2 + (5/4)*n3) * (φ-φ0);
  var Mb = (3*n + 3*n*n + (21/8)*n3) * Math.sin(φ-φ0) * Math.cos(φ+φ0);
  var Mc = ((15/8)*n2 + (15/8)*n3) * Math.sin(2*(φ-φ0)) * Math.cos(2*(φ+φ0));
  var Md = (35/24)*n3 * Math.sin(3*(φ-φ0)) * Math.cos(3*(φ+φ0));
  var M = b * F0 * (Ma - Mb + Mc - Md);              // meridional arc

  var cos3φ = cosφ*cosφ*cosφ;
  var cos5φ = cos3φ*cosφ*cosφ;
  var tan2φ = Math.tan(φ)*Math.tan(φ);
  var tan4φ = tan2φ*tan2φ;

  var I = M + N0;
  var II = (ν/2)*sinφ*cosφ;
  var III = (ν/24)*sinφ*cos3φ*(5-tan2φ+9*η2);
  var IIIA = (ν/720)*sinφ*cos5φ*(61-58*tan2φ+tan4φ);
  var IV = ν*cosφ;
  var V = (ν/6)*cos3φ*(ν/ρ-tan2φ);
  var VI = (ν/120) * cos5φ * (5 - 18*tan2φ + tan4φ + 14*η2 - 58*tan2φ*η2);

  var Δλ = λ-λ0;
  var Δλ2 = Δλ*Δλ, Δλ3 = Δλ2*Δλ, Δλ4 = Δλ3*Δλ, Δλ5 = Δλ4*Δλ, Δλ6 = Δλ5*Δλ;

  var N = I + II*Δλ2 + III*Δλ4 + IIIA*Δλ6;
  var E = E0 + IV*Δλ + V*Δλ3 + VI*Δλ5;

  var north = Number(N.toFixed(3)); // round to mm precision
  var east = Number(E.toFixed(3));

  // console.log(N, E)
  var digits = 6;


  var e100k = Math.floor(east/100000), n100k = Math.floor(north/100000);

 if (e100k<0 || e100k>6 || n100k<0 || n100k>12) return '';

 // translate those into numeric equivalents of the grid letters
 var l1 = (19-n100k) - (19-n100k)%5 + Math.floor((e100k+10)/5);
 var l2 = (19-n100k)*5%25 + e100k%5;

 // compensate for skipped 'I' and build grid letter-pairs
 if (l1 > 7) l1++;
 if (l2 > 7) l2++;
 var letPair = String.fromCharCode(l1+'A'.charCodeAt(0), l2+'A'.charCodeAt(0));

 // strip 100km-grid indices from easting & northing, and reduce precision
 east = Math.floor((east%100000)/Math.pow(10, 5-digits/2));
 north = Math.floor((north%100000)/Math.pow(10, 5-digits/2));

 var gridRef = letPair  + pad(east, digits/2)  + pad(north, digits/2);
  // return new OsGridRef(E, N); // gets truncated to SW corner of 1m grid square
  // console.log(gridRef)
  return gridRef
}

export function toRadians(degrees) {
  return degrees * Math.PI / 180;
}
export function toDegrees(radians) {
  return radians * 180 / Math.PI;
}

function pad(e, w) {
  var n = e.toString();
  while (n.length < w) n = '0' + n;
  return n;
}
