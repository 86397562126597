import React, { Component } from "react";
import { connect } from "react-redux";

import FontAwesome from "react-fontawesome";
import "./StatusBar.css";

class StatusBar extends Component {
  render() {
    return (
      <div className="messageBar">
        <div className="messageBar_row">
          <div className="messageBar_message">
            <FontAwesome name="crosshairs" /> Map Center:
            {this.props.units === "UKGridRef"
              ? this.props.center
              : `lat: ${Math.round(this.props.lat * 1000) / 1000}, lng: ${
                  Math.round(this.props.lng * 1000) / 1000
                }`}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    message: state.message.message,
    center: state.map.ref,
    lat: state.map.lat,
    lng: state.map.lng,
    gps: state.gps,
    units: state.options.locationUnits,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
