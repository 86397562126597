export default {
  fell: {
    color: "#f0f4e7",
  },
  water: {
    color: "#d9e9f9",
  },
  reservoir: {
    color: "#d9e9f9",
  },
  grassland: {
    color: "#f0f4e7",
  },
  wood: {
    color: "#daeabb",
  },
  forest: {
    color: "#daeabb",
  },
  wetland: {
    color: "rgba(50,70,250,.07)",
  },
  scree: {
    color: "rgba(0,0,0,.1)",
  },
  sea: {
    color: "#d9e9f9",
  },
  mud: {
    color: "rgba(228, 213, 170, 0.51)",
  },
  beach: {
    color: "#f5e5b9",
  },
  bare_rock: {
    color: "rgba(0,0,0,.1)",
  },
  coastline2: {
    color: "#ccd6ba",
  },
  elevpolygon1: {
    color: "rgba(255,255,255,.3)",
  },
  elevpolygon2: {
    color: "rgba(255,255,255,.3)",
  },
  building: {
    color: "#666",
  },
};
