import * as actionType from "../actions/ActionType";
import { latLngToGrid } from "../utils";

const mapReducer = (
  state = {
    lat: 57,
    lng: -4.5,
    zoom: 7,
    ref: "",
    mode: "full", // full / driving / road /
  },
  action
) => {
  switch (action.type) {
    case actionType.SET_MAP:
      return Object.assign({}, state, {
        lat: action.lat,
        lng: action.lng,
        zoom: action.zoom,
        ref: latLngToGrid(action.lat, action.lng),
      });
    case actionType.SET_ZOOM:
      return Object.assign({}, state, {
        zoom: action.zoom,
      });
    case actionType.SET_MODE:
      return Object.assign({}, state, {
        mode: action.mode,
      });
    default:
      return state;
  }
};

export default mapReducer;
