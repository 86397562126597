export const TOGGLE_MENU = "TOGGLE_MENU";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const TOGGLE_GRID = "TOGGLE_GRID";
export const TOGGLE_TARGET = "TOGGLE_TARGET";
export const TOGGLE_SCALE = "TOGGLE_SCALE";
export const TOGGLE_PLACENAMES = "TOGGLE_PLACENAMES";
export const TOGGLE_HILLNAMES = "TOGGLE_HILLNAMES";
export const TOGGLE_LAKENAMES = "TOGGLE_LAKENAMES";
export const TOGGLE_CONTOURS = "TOGGLE_CONTOURS";
export const SET_LOCATION_UNIT = "SET_LOCATION_UNIT";
export const SET_DISTANCE_UNIT = "SET_DISTANCE_UNIT";
export const SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE";

export const SET_MAP = "SET_MAP";
export const SET_ZOOM = "SET_ZOOM";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const SET_MODE = "SET_MODE";
