export default {
  motorway: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 5,
      },
    },
    {
      style: {
        color: "#009ac9",
        width: 4,
      },
    },
  ],
  motorway_link: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#009ac9",
        width: 2,
      },
    },
  ],
  trunk: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 4,
      },
    },
    {
      style: {
        color: "#009c5e",
        width: 3,
      },
    },
  ],
  trunk_link: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#009c5e",
        width: 2,
      },
    },
  ],
  primary: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 4,
      },
    },
    {
      zIndex: 210,
      style: {
        color: "#ec0056",
        width: 3,
      },
    },
  ],
  primary_link: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#ec0056",
        width: 2,
      },
    },
  ],

  tertiary: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#ffe066",
        width: 2,
      },
    },
  ],

  secondary: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#b57000",
        width: 2,
      },
    },
  ],

  residential: [
    {
      zIndex: 100,
      style: {
        color: "#333",
        width: 2,
      },
    },
    {
      style: {
        color: "#fff",
        width: 1.5,
      },
    },
  ],
  unclassified: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 3,
      },
    },
    {
      style: {
        color: "#fff",
        width: 2,
      },
    },
  ],
  service: [
    {
      zIndex: 100,
      style: {
        color: "#666",
        width: 3,
      },
    },
    {
      style: {
        color: "#fff",
        width: 2,
      },
    },
  ],

  track: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 2.5,
        lineDash: [5, 4],
      },
    },
    {
      style: {
        color: "#fff",
        width: 1.5,
      },
    },
  ],

  grade1: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 2.5,
        lineDash: [5, 4],
      },
    },
    {
      style: {
        color: "#fff",
        width: 1.5,
      },
    },
  ],

  grade2: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 2.5,
        lineDash: [4, 4],
      },
    },
    {
      style: {
        color: "#fff",
        width: 1.5,
      },
    },
  ],

  grade3: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1.3,
        lineDash: [6, 4],
      },
    },
  ],

  grade4: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1,
        lineDash: [4, 6],
      },
    },
  ],

  grade5: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1,
        lineDash: [2, 4],
      },
    },
  ],

  path: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1.1,
        lineDash: [5, 4],
      },
    },
  ],

  steps: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1.1,
        lineDash: [1, 3],
      },
    },
  ],
  footway: [
    {
      zIndex: 100,
      style: {
        color: "#000",
        width: 1,
        lineDash: [4, 3],
      },
    },
  ],
  cycleway: [
    {
      zIndex: 100,
      style: {
        color: "#00653d",
        width: 1.5,
        lineDash: [8, 3],
      },
    },
  ],
  bridleway: [
    {
      zIndex: 100,
      style: {
        color: "#c9025f",
        width: 1.5,
        lineDash: [6, 5],
      },
    },
  ],
  contour: [
    {
      zIndex: 50,
      style: {
        color: "#db8f60",
        width: 0.2,
      },
    },
  ],
  tram: [
    {
      style: {
        color: "#000",
        width: 1.5,
      },
    },
  ],
  rail: [
    {
      style: {
        color: "#000",
        width: 1.2,
      },
    },
  ],
  line: [
    {
      style: {
        color: "#000",
        width: 0.5,
      },
    },
    {
      style: {
        color: "#000",
        width: 2,
        lineDash: [1, 20],
        lineCap: "square",
      },
    },
  ],
  coastline: [
    {
      style: {
        color: "#1f9fd2",
        width: 1,
      },
    },
  ],
  canal: [
    {
      style: {
        color: "#1f9fd2",
        width: 2,
      },
    },
  ],
  river: [
    {
      zIndex: 70,
      style: {
        color: "#1f9fd2",
        width: 1,
      },
    },
  ],
  stream: [
    {
      style: {
        color: "#1f9fd2",
        width: 1,
      },
    },
  ],
  water: [
    {
      style: {
        color: "#1f9fd2",
        width: 0.5,
      },
    },
  ],

  wood: [
    {
      style: {
        color: "#abccae",
        width: 0.5,
      },
    },
  ],

  forest: [
    {
      style: {
        color: "#abccae",
        width: 0.5,
      },
    },
  ],

  grid: [
    {
      style: {
        color: "#1f9fd2",
        width: 0.3,
      },
    },
  ],
  barrier: [
    {
      style: {
        color: "#666666",
        width: 0.3,
      },
    },
  ],
};
